<template>
  <div>
    <div class="tab-content">
      <div class="align-start">
        <div class="upload-image mt-4">
          <div class="upload-image__item">
            <div class="upload-image__top">
              <div class="cat-icon-container">
                <img
                  class="image-container-img"
                  :src="inputData.thumbnail_src ? inputData.thumbnail_src : '/img/category-icons/thumbnail-default.svg'"
                  alt="img"
                  style="max-height:108px"
                />
                <div
                  class="img-remove"
                  v-show="inputData.thumbnail_src != '/img/category-icons/thumbnail-default.svg'"
                  @click="removeImage()"
                >
                  X Remove
                </div>
              </div>
            </div>
            <div class="upload-image__bottom">
              <input
                type="file"
                :name="inputData.thumbnail"
                id="inputIcon"
                class="hidden-input"
                @change="changeThumbnail()"
              />
              <label for="inputIcon" class="file-upload">
                <fa-icon icon="upload" class="my-icon" />
                Ubah Thumbnail
              </label>
            </div>
          </div>
        </div>
        <div class="ml-0 ml-md-4 flex-1 sm-w-100">
          <label>
            Nama Playlist
          </label>
          <b-form-input v-model="inputData.name"></b-form-input>

          <label class="mt-4">
            Deskripsi
          </label>
          <b-textarea v-model="inputData.description" rows="3"></b-textarea>

          <div class="space-between align-start mt-4">
            <div class="align-start">
              <div class="mr-3 mb-4">
                <label>
                  Jenjang
                </label>
                <b-form-select
                  v-model="inputData.level_id"
                  :options="levelOption"
                  @change="changeLevel(null, null, null)"
                ></b-form-select>
              </div>
              <div class="mr-3 mb-4">
                <label>
                  Kelas
                </label>
                <b-form-select
                  v-model="inputData.class_id"
                  :options="classOption"
                  @change="changeClass(null, null)"
                ></b-form-select>
              </div>
              <div class="mr-3 mb-4">
                <label>
                  Mata Pelajaran
                </label>
                <b-form-select
                  v-model="inputData.subject_id"
                  :options="subjectOption"
                  @change="changeSubject(null)"
                ></b-form-select>
              </div>
              <div class="mr-3 mb-4">
                <label>
                  Bab
                </label>
                <b-form-select
                  v-model="inputData.chapter_id"
                  :options="chapterOption"
                ></b-form-select>
              </div>
            </div>

            <my-button
              type="blue"
              disabled
              v-if="showLoading"
              rounded
            >
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </my-button>
            <my-button type="blue" rounded v-else  @click="save">
              <fa-icon class="mr-1" icon="save"> </fa-icon>
              Simpan
            </my-button>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content mt-4">
      <div class="space-between align-center">
        <h2 class="color3">
          Daftar Konten
        </h2>

        <div class="align-center">
          <my-button class="mr-2" type="outline-orange" @click="draggable">
            <fa-icon class="mr-1" icon="sort"></fa-icon> Atur Urutan
          </my-button>

          <div class="p-relative">
            <div class="product-popup" v-if="showPopup">
              <div class="product-popup__item" @click="handleItemClick('quiz')">
                <img src="/img/icons/quizhover.svg" alt="" />
                <span>Quiz</span>
              </div>
              <div
                class="product-popup__item"
                @click="handleItemClick('youtube')"
              >
                <img src="/img/icons/yutubhover.svg" alt="" />
                <span>Video YouTube</span>
              </div>

              <div
                class="product-popup__item"
                @click="handleItemClick('video')"
              >
                <img src="/img/icons/videohover.svg" alt="" />
                <span>Video</span>
              </div>

              <div
                class="product-popup__item"
                @click="handleItemClick('article')"
              >
                <img src="/img/icons/pdfhover.svg" alt="" />
                <span>Artikel+PDF</span>
              </div>
            </div>
            <img
              src="/img/icons/product-popup-arrow.png"
              alt="arr"
              class="product-popup-arrow"
              v-if="showPopup"
            />

            <my-button type="orange" @click="buatContent" @blur="handleBlur">
              <fa-icon icon="plus-circle" class="mr-1" /> Add Content
            </my-button>
          </div>
        </div>
      </div>
      <div class="aspace-between aalign-center mt-2 mb-2 mt-md-4 mb-md-4 product-one-noDrag" style="font-weight: 700;color: #2fcc70;padding: 0px;background: #ddffeb; padding: 10px; text-align: center; text-transform: capitalize;" v-show="isDraggable">DRAG & DROP KONTEN UNTUK ATUR URUTAN</div>

      <div class="border-card mt-4" v-show="items.length + itemsQuiz.length <= 0">
        <div class="text-center">
          <h4 class="mt-2 mb-2 color6">
            Belum ada konten
          </h4>
        </div>
      </div>
      <!-- Draggable Goes Here -->
      <!-- <draggable v-model="items" tag="tbody" @change="log"> -->
      <div v-if="isDraggable">
        <draggable v-model="items" @change="orderContent">
          <div class="border-card mt-4" v-for="item in items" :key="item.id" style="cursor: pointer">
            <div class="space-between align-center">
              <div class="flex-1 align-start">
                <img :src="item.type == 'video'? '/img/icons/video-orange.svg' : item.type == 'pdf' ? '/img/icons/pdf.svg' : '/img/icons/yutubhover.svg'" alt="" />
                <div class="ml-3">
                  <p class="content-type">
                    {{item.type}}
                  </p>
                  <h4 class="color-black">
                    {{item.name}}
                  </h4>
                </div>
              </div>

              <div class="align-center">
                <b-form-checkbox switch v-model="item.publish" @change="changePublish(item)"></b-form-checkbox>
                <my-button type="outline-red" narrow class="ml-2 mr-3" @click="handleDelete(item.id)">
                  <fa-icon icon="trash-alt"></fa-icon>
                </my-button>

                <my-button type="blue" narrow @click="handleEdit(item)">
                  <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>
                  View Detail & Edit
                </my-button>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div v-else>
        <div class="border-card mt-4" v-for="item in items" :key="item.id">
          <div class="space-between align-center">
            <div class="flex-1 align-start">
              <img :src="item.type == 'video'? '/img/icons/video-orange.svg' : item.type == 'pdf' ? '/img/icons/pdf.svg' : '/img/icons/yutubhover.svg'" alt="" />
              <div class="ml-3">
                <p class="content-type">
                  {{item.type}}
                </p>
                <h4 class="color-black">
                  {{item.name}}
                </h4>
              </div>
            </div>

            <div class="align-center">
              <b-form-checkbox switch v-model="item.publish" @change="changePublish(item)"></b-form-checkbox>
              <my-button type="outline-red" narrow class="ml-2 mr-3" @click="handleDelete(item.id)">
                <fa-icon icon="trash-alt"></fa-icon>
              </my-button>

              <my-button type="blue" narrow @click="handleEdit(item)">
                <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>
                View Detail & Edit
              </my-button>
            </div>
          </div>
        </div>
      </div>

      <div class="border-card mt-4" v-if="itemsQuiz.length > 0" v-for="(qz, idx) in itemsQuiz">
        <div class="space-between align-center">
          <div class="align-center flex-1">
            <img src="/img/icons/quiz.svg" alt="" />
            <h4 class="color-black ml-2 mb-0">
              QUIZ {{qz.name}}
            </h4>
          </div>
          <my-button type="blue" narrow @click="handleEdit(qz, 'quiz')">
            <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>
            Edit
          </my-button>
          <my-button type="outline-red" narrow class="ml-2 mr-3" @click="handleDelete(qz.id, 'quiz')">
            <fa-icon icon="trash-alt"></fa-icon>&nbsp;Remove
          </my-button>
          <my-button type="orange" @click="handleItemClick('question', qz.id)">
            <fa-icon icon="plus-circle" class="mr-1" /> New Question
          </my-button>
        </div>

        <draggable v-model="qz.questions" @change="orderQUestion(qz.id, idx)">
          <div
            class="quiz-question align-start"
            v-for="(item, i) in qz.questions"
          >
            <div class="flex-1 mr-4 mb-4 mb-md-0">
              {{i+1}}. 
              <span v-katex="{expression: item.question.substr(0,50), options: { macros: {' ': '\\space '}, maxSize:50 }}"></span>
            </div>

            <div class="align-center">
              <b-form-checkbox switch v-model="item.publish ? true : false" @change="changePublish(item, 'question')"></b-form-checkbox>
              <my-button type="outline-red" narrow class="ml-2 mr-3" @click="handleDelete(item.id, 'question')">
                <fa-icon icon="trash-alt"></fa-icon>
              </my-button>

              <my-button type="blue" narrow @click="handleEdit(item, 'question')">
                <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>
                View Detail & Edit
              </my-button>
            </div>
          </div>
        </draggable>
      </div>

    </div>

    <b-modal v-model="ytModal" id="ytModal" hide-header hide-footer centered>
      <modal-cross @close="ytModal = false"></modal-cross>

      <div class="p-4">
        <h2 class="text-center color2">
          {{inputYoutube.id ? 'Edit Youtube' : 'Add New Youtube'}}
        </h2>

        <b-row class="mt-4">
          <b-col md="6">
            <label>Judul Video</label>
            <b-form-input v-model="inputYoutube.name"></b-form-input>

            <iframe
              width="100%"
              height="250px"
              :src="inputYoutube.url.split('watch?v=').join('embed/').split('&')[0]"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="mt-4"
            ></iframe>

            <label class="mt-4-5">
              Insert Youtube Link
            </label>

            <b-form-input
              v-model="inputYoutube.url"
            ></b-form-input>
          </b-col>

          <b-col md="6">
            <label>Notes</label>
            <b-textarea v-model="inputYoutube.description" rows="10"></b-textarea>
            <label class="mt-4">Duration</label>
            <b-form-input placeholder="ex. 20:21" v-model="inputYoutube.duration"></b-form-input>
            <label class="mt-4">Status</label>
            <b-form-select
              v-model="inputYoutube.publish"
              :options="statuses"
            ></b-form-select>
          </b-col>
        </b-row>

        <my-button
          type="blue"
          disabled
          size="lg"
          rounded
          class="mt-4"
          block
          v-if="showContentLoading"
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
        <my-button type="blue" size="lg" rounded v-else class="mt-4" block @click="saveYoutube">
          SAVE
        </my-button>
      </div>
    </b-modal>

    <b-modal
      v-model="videoModal"
      id="videoModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="videoModal = false"></modal-cross>

      <div class="p-4">
        <h2 class="text-center color2">
          {{inputVideo.id ? 'Edit Original Video' : 'Add New Original Video'}}
        </h2>

        <label class="mt-1">Judul Video</label>
        <b-form-input v-model="inputVideo.name"></b-form-input>

        <label class="mt-4">Player ID</label>
        <b-form-input placeholder="Type here..." v-model="inputVideo.player_id"></b-form-input>

        <label class="mt-4">Duration</label>
        <b-form-input placeholder="ex. 20:21" v-model="inputVideo.duration"></b-form-input>

        <label class="mt-4">Notes</label>
        <b-textarea placeholder="Type here..." rows="4" v-model="inputVideo.description"></b-textarea>

        <label class="mt-4">Status</label>
        <b-form-select
          v-model="inputVideo.publish"
          :options="statuses"
        ></b-form-select>

        <my-button
          type="blue"
          disabled
          size="lg"
          rounded
          class="mt-4"
          block
          v-if="showContentLoading"
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
        <my-button type="blue" size="lg" v-else rounded class="mt-4" block @click="saveVideo">
          SAVE
        </my-button>
      </div>
    </b-modal>

    <b-modal
      v-model="articleModal"
      id="articleModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="articleModal = false"></modal-cross>

      <div class="p-4">
        <h2 class="text-center color2">
          {{inputArticle.id ? 'Edit Article' : 'Add New Article'}}
        </h2>

        <label class="mt-4">Judul Artikel</label>
        <b-form-input v-model="inputArticle.name"></b-form-input>

        <label class="mt-4">Description</label>

        <b-textarea
          placeholder="Type here..."
          rows="4"
          v-model="inputArticle.description"
        ></b-textarea>

        <label class="mt-4">File</label>
        <div class="upload-image">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top" v-show="inputArticle.file_name">
              <div class="align-center">
                <strong class="color7 mr-1">
                  <fa-icon icon="file-pdf"></fa-icon>
                </strong>
                <strong>{{inputArticle.file_name}}</strong>
                <div class="file-remove ml-2" @click="removeFile">
                  X Remove
                </div>
              </div>
            </div>

            <div class="upload-image__bottom">
              <input type="file" name="file1" id="file1" class="hidden-input" @change="changeFile" />
              <label for="file1" class="file-upload">
                <fa-icon icon="upload" class="my-icon" />
                Upload File
              </label>
            </div>
          </div>
        </div>

        <label class="mt-4">Status</label>
        <b-form-select
          v-model="inputArticle.publish"
          :options="statuses"
        ></b-form-select>

        <my-button
          type="blue"
          disabled
          size="lg"
          rounded
          class="mt-4"
          block
          v-if="showContentLoading"
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
        <my-button type="blue" size="lg" v-else rounded class="mt-4" block @click="saveArticle">
          SAVE
        </my-button>
      </div>
    </b-modal>

    <b-modal
      v-model="quizModal"
      id="quizModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="quizModal = false"></modal-cross>

      <div class="p-4">
        <h2 class="text-center color2">
          {{inputQuiz.id ? 'Edit Quiz' : 'Add New Quiz'}}
        </h2>

        <label class="mt-4">Judul Quiz</label>
        <b-form-input v-model="inputQuiz.name"></b-form-input>

        <label class="mt-4">Duration (in minutes)</label>
        <b-form-input type="number" v-model="inputQuiz.duration"></b-form-input>

        <my-button
          type="blue"
          disabled
          size="lg"
          rounded
          class="mt-4"
          block
          v-if="showContentLoading"
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
        <my-button type="blue" size="lg" v-else rounded class="mt-4" block @click="saveQuiz">
          SAVE
        </my-button>
      </div>
    </b-modal>

    <b-modal
      v-model="questionModal"
      id="questionModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="questionModal = false"></modal-cross>

      <div class="p-4">
        <h2 class="text-center color2">
          Add New Question
        </h2>

        <label class="mt-1">Description</label>

        <b-textarea
          placeholder="Type here..."
          rows="4"
          v-model="inputQuestion.question"
        ></b-textarea>

        <math-editor v-model="latexCode" @add="addLatex($event)"></math-editor>

        <div class="upload-image mt-4" v-show="inputQuestion.gallery_url">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top">
              <img :src="inputQuestion.gallery_url" alt="img" style="max-height:108px" />
              <div class="img-remove" v-if="inputQuestion.gallery_url" @click="removePicture(0)">
                X Remove
              </div>
            </div>
          </div>

          <!-- <div class="upload-image__item c-pointer">
            <img src="/img/add-image.png" alt="" />
          </div> -->
        </div>
        <input type="file" name="file1" id="file1" class="hidden-input" @change="changePicture(0)" />
        <label for="file1" class="file-upload">
          <fa-icon icon="upload" class="my-icon" />
          Upload Gambar Soal
        </label>
        </br>
        <label class="mt-4">
          Pilihan Jawaban
        </label>

        <div class="align-start mt-3">
          <div class="answer-index">
            <span> A </span>
          </div>
          <div class="flex-1">
            <b-textarea
              placeholder="Answer..."
              rows="3"
              v-model="inputQuestion.option1.option"
            ></b-textarea>
            <div class="upload-image mt-4" v-show="inputQuestion.option1.picture_url">
              <div class="upload-image__item mr-4">
                <div class="upload-image__top">
                  <img :src="inputQuestion.option1.picture_url" alt="img" style="max-height:108px" />
                  <div class="img-remove" v-if="inputQuestion.option1.picture_url" @click="removePicture(1)">
                    X Remove
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              name="ansfile1"
              id="ansfile1"
              class="hidden-input"
              @change="changePicture(1)"
            />
            <label for="ansfile1" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Gambar Pilihan Jawaban
            </label>
          </div>
        </div>

        <div class="align-start mt-4">
          <div class="answer-index">
            <span> B </span>
          </div>
          <div class="flex-1">
            <b-textarea placeholder="Answer..." rows="3" v-model="inputQuestion.option2.option"></b-textarea>
            <div class="upload-image mt-4" v-show="inputQuestion.option2.picture_url">
              <div class="upload-image__item mr-4">
                <div class="upload-image__top">
                  <img :src="inputQuestion.option2.picture_url" alt="img" style="max-height:108px" />
                  <div class="img-remove" v-if="inputQuestion.option2.picture_url" @click="removePicture(2)">
                    X Remove
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              name="ansfile2"
              id="ansfile2"
              class="hidden-input"
              @change="changePicture(2)"
            />
            <label for="ansfile2" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Gambar Pilihan Jawaban
            </label>
          </div>
        </div>

        <div class="align-start mt-4">
          <div class="answer-index">
            <span> C </span>
          </div>
          <div class="flex-1">
            <b-textarea placeholder="Answer..." rows="3" v-model="inputQuestion.option3.option"></b-textarea>
            <div class="upload-image mt-4" v-show="inputQuestion.option3.picture_url">
              <div class="upload-image__item mr-4">
                <div class="upload-image__top">
                  <img :src="inputQuestion.option3.picture_url" alt="img" style="max-height:108px" />
                  <div class="img-remove" v-if="inputQuestion.option3.picture_url" @click="removePicture(3)">
                    X Remove
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              name="ansfile3"
              id="ansfile3"
              class="hidden-input"
              @change="changePicture(3)"
            />
            <label for="ansfile3" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Gambar Pilihan Jawaban
            </label>
          </div>
        </div>

        <div class="align-start mt-4">
          <div class="answer-index">
            <span> D </span>
          </div>
          <div class="flex-1">
            <b-textarea placeholder="Answer..." rows="3" v-model="inputQuestion.option4.option"></b-textarea>
            <div class="upload-image mt-4" v-show="inputQuestion.option4.picture_url">
              <div class="upload-image__item mr-4">
                <div class="upload-image__top">
                  <img :src="inputQuestion.option4.picture_url" alt="img" style="max-height:108px" />
                  <div class="img-remove" v-if="inputQuestion.option4.picture_url" @click="removePicture(4)">
                    X Remove
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              name="ansfile4"
              id="ansfile4"
              class="hidden-input"
              @change="changePicture(4)"
            />
            <label for="ansfile4" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Gambar Pilihan Jawaban
            </label>
          </div>
        </div>

        <div class="align-start mt-4">
          <div class="answer-index">
            <span> E </span>
          </div>
          <div class="flex-1">
            <b-textarea placeholder="Answer..." rows="3" v-model="inputQuestion.option5.option"></b-textarea>
            <div class="upload-image mt-4" v-show="inputQuestion.option5.picture_url">
              <div class="upload-image__item mr-4">
                <div class="upload-image__top">
                  <img :src="inputQuestion.option5.picture_url" alt="img" style="max-height:108px" />
                  <div class="img-remove" v-if="inputQuestion.option5.picture_url" @click="removePicture(5)">
                    X Remove
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              name="ansfile5"
              id="ansfile5"
              class="hidden-input"
              @change="changePicture(5)"
            />
            <label for="ansfile5" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Gambar Pilihan Jawaban
            </label>
          </div>
        </div>

        <label class="mt-4">
          Jawaban Benar
        </label>
        <div class="flex">
          <div>
            <b-form-select
              v-model="inputQuestion.answer"
              :options="answers"
            ></b-form-select>
          </div>
        </div>

        <my-button
          type="blue"
          disabled
          size="lg"
          rounded
          class="mt-4"
          block
          v-if="showContentLoading"
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
        <my-button type="blue" size="lg" v-else rounded class="mt-4" block @click="saveQuestion">
          SAVE
        </my-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { products } from "../../../data/products";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  components: {
    draggable
  },
  data() {
    return {
      latexCode: "\\frac{a_i}{1+x}",
      isDraggable: false,
      itemsQuiz: [],
      showContentLoading: false,
      showLoading: false,
      levelOption: [],
      classOption: [{value: null, text: "Tidak Ada Kelas"}],
      subjectOption: [{value: null, text: "Tidak Ada Mata Pelajaran"}],
      chapterOption: [{value: null, text: "Tidak Ada Materi"}],
      inputData: {
        name: "",
        description: "",
        level_id: null,
        class_id: null,
        subject_id: null,
        chapter_id: null,
        id: null,
        thumbnail_src: "/img/category-icons/thumbnail-default.svg",
        thumbnail: null,
        removeOld: 0
      },
      inputYoutube: {
        url:"",
        name: "",
        description: "",
        type: "youtube",
        publish: 0,
        id: null,
        duration: ""
      },
      inputVideo: {
        name: "",
        description: "",
        type: "video",
        player_id: "",
        publish: 0,
        id: null,
        duration: ""
      },
      inputArticle: {
        name: "",
        description: "",
        file: null,
        type: "pdf",
        publish: 0,
        file_name: "",
        id: null,
        removeOld: 0
      },
      inputQuiz: {
        name:"",
        duration: 0,
        id: null
      },
      inputQuestion: {
        quiz_id: null,
        id: null,
        answer: 1,
        question: "",
        gallery: "",
        gallery_url: "",
        removeOld: 0,
        option1: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option2: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option3: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option4: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option5: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        }
      },
      items: [],
      answers: [
        {
          text: "A",
          value: 1
        },
        {
          text: "B",
          value: 2
        },
        {
          text: "C",
          value: 3
        },
        {
          text: "D",
          value: 4
        },
        {
          text: "E",
          value: 5
        }
      ],
      showPopup: false,
      questions: [
        {
          text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit",
          enabled: true
        },
        {
          text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.",
          enabled: false
        },
        {
          text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.",
          enabled: true
        }
      ],
      statuses: [
        {
          text: "Publish",
          value: "1"
        },
        {
          text: "Draft",
          value: "0"
        }
      ],
      ytModal: false,
      videoModal: false,
      articleModal: false,
      questionModal: false,
      quizModal: false
    };
  },

  mounted() {
    this.levelList();
    this.getDetail();
    this.contentList();
    this.quizList();
  },

  methods: {
    ...mapActions({
      getLevelList: types.GET_LEVEL_LIST,
      getSubjectList: types.GET_SUBJECT_LIST,
      getChapterList: types.GET_CHAPTER_LIST,
      getClassList: types.GET_CLASS_LIST,
      getSectionDetail: types.GET_SECTION_DETAIL,
      updateSection: types.UPDATE_SECTION,
      setNewContent: types.SET_NEW_CONTENT,
      updateContent: types.UPDATE_CONTENT,
      deleteContent: types.DELETE_CONTENT,
      getContentList: types.GET_CONTENT_LIST,
      publishContent: types.PUBLISH_CONTENT,
      setOrder: types.SET_ORDER,
      setNewQuiz: types.SET_NEW_QUIZ,
      updateQuiz: types.UPDATE_QUIZ,
      deleteQuiz: types.DELETE_QUIZ,
      getQuizList: types.GET_QUIZ_LIST,
      getQuestionDetail: types.GET_QUESTION_DETAIL,
      setNewQuestion: types.SET_NEW_QUESTION,
      updateQuestion: types.UPDATE_QUESTION,
      deleteQuestion: types.DELETE_QUESTION,
      updateQuestion: types.UPDATE_QUESTION,
      publishQuestion: types.PUBLISH_QUESTION,
      setOrderQuestion: types.SET_ORDER_QUESTION
    }),
    addLatex(data) {
      this.inputQuestion.question += data;
      this.latexCode = "";
    },
    handleDelete(id, type) {
      this.$bvModal.msgBoxConfirm('Delete konten?', {
        title: 'Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(value) {
            if(type == 'quiz') {
              this.deleteQuiz({id:id})
              .then(response => {
                this.quizList();
                this.showMessageBox("Data berhasil dihapus", "Sukses", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
            } else if (type == 'question') {
              this.deleteQuestion({id:id})
              .then(response => {
                this.quizList();
                this.showMessageBox("Data berhasil dihapus", "Sukses", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
            }else {
              this.deleteContent({id:id})
              .then(response => {
                this.contentList();
                this.showMessageBox("Data berhasil dihapus", "Sukses", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
            }
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    handleEdit(item, type="") {
      if(item.type=='youtube') {
        this.inputYoutube = {
          url: item.url,
          name: item.name,
          description: item.description,
          type: "youtube",
          publish: item.publish ? 1 : 0,
          id: item.id,
          duration: item.duration
        }
        this.ytModal = true;
      } 
      if(item.type=='video') {
        this.inputVideo = {
          name: item.name,
          description: item.description,
          type: "video",
          player_id: item.player_id,
          publish: item.publish ? 1 : 0,
          id: item.id,
          duration: item.duration
        }
        this.videoModal = true;
      } 
      if(item.type=='pdf') {
        this.inputArticle = {
          name: item.name,
          description: item.description,
          file: null,
          type: "pdf",
          publish: item.publish ? 1 : 0,
          file_name: item.url ? item.url.split('https://fitrahworld.sgp1.digitaloceanspaces.com/alfathbelajar/file/').join('') : item.url,
          id: item.id,
          removeOld: 0
        }
        this.articleModal = true;
      }
      if(type=='quiz') {
        this.inputQuiz = {
          name: item.name,
          duration: item.duration,
          id: item.id
        }
        this.quizModal = true;
      }
      if(type == 'question') {
        this.getQuestionDetail(item.id)
        .then(response => {
          var res = response[0];
          var question = {
            quiz_id: res.quiz_id,
            question: res.question,
            answer: res.answer,
            id: res.id,
            gallery: null,
            gallery_url: res.gallery,
            removeOld: 0,
            option1: {
              option: "",
              picture: null,
              picture_url: "",
              removeOld: 0
            },
            option2: {
              option: "",
              picture: null,
              picture_url: "",
              removeOld: 0
            },
            option3: {
              option: "",
              picture: null,
              picture_url: "",
              removeOld: 0
            },
            option4: {
              option: "",
              picture: null,
              picture_url: "",
              removeOld: 0
            },
            option5: {
              option: "",
              picture: null,
              picture_url: "",
              removeOld: 0
            }
          }
          for (var i = 0; i < res.options_json.length; i++) {
            question['option'+res.options_json[i].key].option = res.options_json[i].option
            question['option'+res.options_json[i].key].picture = null;
            question['option'+res.options_json[i].key].picture_url = res.options_json[i].picture;
            question['option'+res.options_json[i].key].removeOld = 0
          }
          this.inputQuestion = question;
          this.questionModal = true;
        }).catch(error => {
          this.showMessageBox(error, "Failed", "success");
        })
      }
    },
    levelList() {
      this.getLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [{value: null, text: "Pilih"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    classList() {
      if(this.inputData.level_id) {
        this.getClassList({params: {level: this.inputData.level_id}})
          .then(response => {
            var res = response;
            if(response.length > 0) {
              this.classOption = [{value: null, text: "Pilih"}];
            } else {
              this.classOption = [{value: null, text: "Tidak Ada Kelas"}];
            }
            for (var i = 0; i < res.length; i++) {
              var dataclass = {
                value: res[i].id,
                text: res[i].major ? res[i].name+' - '+res[i].major:res[i].name
              };
              this.classOption.push(dataclass);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.classOption = [{value: null, text: "Tidak Ada Kelas"}];
      }
    },
    subjectList() {
      if(this.inputData.level_id && this.inputData.class_id) {
        this.getSubjectList({params: {level: this.inputData.level_id, class: this.inputData.class_id}})
          .then(response => {
            var res = response;
            if(response.length > 0) {
              this.subjectOption = [{value: null, text: "Pilih"}];
            } else {
              this.subjectOption = [{value: null, text: "Tidak Ada Mata Pelajaran"}];
            }
            for (var i = 0; i < res.length; i++) {
              var datasubject = {
                value: res[i].id,
                text: res[i].name
              };
              this.subjectOption.push(datasubject);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.subjectOption = [{value: null, text: "Tidak Ada Mata Pelajaran"}];
      }
    },
    chapterList() {
      if(this.inputData.level_id && this.inputData.class_id && this.inputData.subject_id) {
        this.getChapterList({params: {level: this.inputData.level_id, subject: this.inputData.subject_id, class: this.inputData.class_id}})
          .then(response => {
            var res = response;
            if(response.length > 0) {
              this.chapterOption = [{value: null, text: "Pilih"}];
            } else {
              this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
            }
            for (var i = 0; i < res.length; i++) {
              var datasubject = {
                value: res[i].id,
                text: res[i].name+' ('+res[i].curriculum+')'
              };
              this.chapterOption.push(datasubject);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
      }
    },
    contentList() {
      this.getContentList({params: {section: this.$route.params.id}})
        .then(response => {
          var res = response;
          this.items = response;
          for (var i = 0; i < this.items.length; i++) {
            this.items[i].publish = this.items[i].publish==1 ? true : false
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    quizList() {
      this.getQuizList({params: {section: this.$route.params.id}})
        .then(response => {
          var res = response;
          this.itemsQuiz = response;
          for (var i = 0; i < this.itemsQuiz.length; i++) {
            this.itemsQuiz[i].publish = this.itemsQuiz[i].publish==1 ? true : false
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    changeLevel(classId = null, subjectId = null, chapterId = null) {
      this.classList();
      this.inputData.class_id = classId;
      this.inputData.subject_id = subjectId;
      this.inputData.chapter_id = chapterId;
      this.subjectOption = [{value: null, text: "Tidak Ada Mata Pelajaran"}];
      this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
    },
    changeClass(subjectId = null, chapterId = null) {
      this.subjectList();
      this.inputData.subject_id = subjectId;
      this.inputData.chapter_id = chapterId;
      this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
    },
    changeSubject(chapterId = null) {
      this.inputData.chapter_id = chapterId;
      this.chapterList();
    },
    draggable() {
      this.isDraggable = !this.isDraggable;
    },
    getDetail() {
      this.getSectionDetail(this.$route.params.id)
        .then(response => {
          var res = response;
          this.inputData.name = res.name;
          this.inputData.description = res.description;
          this.inputData.id = res.id;
          this.inputData.level_id = res.level_id;
          this.inputData.major_id = res.major_id;
          this.inputData.chapter_id = res.chapter_id;
          this.inputData.class_id = res.class_id;
          this.inputData.subject_id = res.subject_id;
          this.inputData.curriculum_id = res.curriculum_id;
          this.inputData.thumbnail_src = res.thumbnail ? res.thumbnail : '/img/category-icons/thumbnail-default.svg';
          this.inputData.thumbnail = null;
          this.changeLevel(res.class_id, res.subject_id, res.chapter_id);
          this.changeClass(res.subject_id, res.chapter_id);
          this.changeSubject(res.chapter_id);
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    orderContent() {
      var orderAllIds = [];
      var orderAllIdsPublish = [];
      for (var i = 0; i < this.items.length; i++) {
        orderAllIds.push(this.items[i].id);
        if(this.items[i].publish) {
          orderAllIdsPublish.push(this.items[i].id);
        }
      }

      this.setOrder({
        section_id: this.$route.params.id,
        content_order: orderAllIds.join(','),
        content_order_publish: orderAllIdsPublish.join(',')
      })
      .then((response) => {
        this.showMessageBox('Berhasil mengubah urutan konten', 'Success', 'success');
      })
      .catch((error) => {
        this.showMessageBox(error, 'Gagal mengubah urutan konten', 'success');
      });
    },
    orderQUestion(id, idx) {
      var orderAllIds = [];
      var orderAllIdsPublish = [];
      for (var i = 0; i < this.itemsQuiz[idx].questions.length; i++) {
        orderAllIds.push(this.itemsQuiz[idx].questions[i].id);
        if(this.itemsQuiz[idx].questions[i].publish) {
          orderAllIdsPublish.push(this.itemsQuiz[idx].questions[i].id);
        }
      }

      this.setOrderQuestion({
        id: id,
        order: orderAllIds.join(','),
        order_publish: orderAllIdsPublish.join(',')
      })
      .then((response) => {
        this.showMessageBox('Berhasil mengubah urutan soal', 'Success', 'success');
      })
      .catch((error) => {
        this.showMessageBox(error, 'Gagal mengubah urutan soal', 'success');
      });
    },
    save() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputData.name);
      newFormData.append("description", this.inputData.description);
      newFormData.append("chapter_id", this.inputData.chapter_id);
      newFormData.append("id", this.inputData.id);
      if(this.inputData.thumbnail) {
        newFormData.append("thumbnail", this.inputData.thumbnail);
      }
      if(this.inputData.removeOld) {
        newFormData.append("removeOld", this.inputData.removeOld);
      }
      this.updateSection(newFormData)
        .then(response => {
          this.showLoading = false;
          // this.chapterList();
          this.showMessageBox("Berhasil mengubah data", "Sukses", "success");
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    saveYoutube() {
      this.showContentLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputYoutube.name);
      newFormData.append("description", this.inputYoutube.description);
      newFormData.append("url", this.inputYoutube.url);
      newFormData.append("section_id", this.$route.params.id);
      newFormData.append("type", this.inputYoutube.type);
      newFormData.append("publish", this.inputYoutube.publish);
      newFormData.append("duration", this.inputYoutube.duration);
      if(this.inputYoutube.id) {
        newFormData.append("id", this.inputYoutube.id);
        this.updateContent(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.ytModal = false;
          this.showMessageBox("Berhasil update konten youtube", "Sukses", "success");
          this.contentList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.setNewContent(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.ytModal = false;
          this.showMessageBox("Berhasil menambah konten youtube", "Sukses", "success");
          this.contentList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      }
    },
    saveVideo() {
      this.showContentLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputVideo.name);
      newFormData.append("description", this.inputVideo.description);
      newFormData.append("player_id", this.inputVideo.player_id);
      newFormData.append("section_id", this.$route.params.id);
      newFormData.append("type", this.inputVideo.type);
      newFormData.append("publish", this.inputVideo.publish);
      newFormData.append("duration", this.inputVideo.duration);
      if(this.inputVideo.id) {
        newFormData.append("id", this.inputVideo.id);
        this.updateContent(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.videoModal = false;
          this.showMessageBox("Berhasil update konten video", "Sukses", "success");
          this.contentList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.setNewContent(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.videoModal = false;
          this.showMessageBox("Berhasil menambah konten video", "Sukses", "success");
          this.contentList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      }
    },
    saveArticle() {
      this.showContentLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputArticle.name);
      newFormData.append("description", this.inputArticle.description);
      newFormData.append("section_id", this.$route.params.id);
      newFormData.append("type", this.inputArticle.type);
      newFormData.append("publish", this.inputArticle.publish);
      if(this.inputArticle.removeOld) {
        newFormData.append("removeOld", this.inputArticle.removeOld);
      }
      if(this.inputArticle.file) {
        newFormData.append("file", this.inputArticle.file);
      }
      if(this.inputArticle.id) {
        newFormData.append("id", this.inputArticle.id);
        this.updateContent(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.articleModal = false;
          this.showMessageBox("Berhasil update konten artikel", "Sukses", "success");
          this.contentList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.setNewContent(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.articleModal = false;
          this.showMessageBox("Berhasil menambah konten artikel", "Sukses", "success");
          this.contentList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      }
    },
    saveQuiz() {
      this.showContentLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputQuiz.name);
      newFormData.append("duration", this.inputQuiz.duration);
      newFormData.append("section_id", this.$route.params.id);
      if(this.inputQuiz.id) {
        newFormData.append("id", this.inputQuiz.id);
        this.updateQuiz(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.quizModal = false;
          this.showMessageBox("Berhasil update quiz", "Sukses", "success");
          this.quizList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.setNewQuiz(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.quizModal = false;
          this.showMessageBox("Berhasil menambah quiz", "Sukses", "success");
          this.quizList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      }
    },
    saveQuestion() {
      this.showContentLoading = true;
      let newFormData = new FormData();
      newFormData.append("question", this.inputQuestion.question);
      newFormData.append("answer", this.inputQuestion.answer);
      newFormData.append("section_id", this.$route.params.id);
      newFormData.append("publish", this.inputQuestion.publish);
      newFormData.append("quiz_id", this.inputQuestion.quiz_id);
      if(this.inputQuestion.removeOld) {
        newFormData.append("removeOld", this.inputQuestion.removeOld);
      }
      if(this.inputQuestion.gallery) {
        newFormData.append("gallery", this.inputQuestion.gallery);
      }
      for (var i = 1; i < 6; i++) {
        if(this.inputQuestion['option'+i].option|| this.inputQuestion['option'+i].picture) {
          newFormData.append("option"+i, this.inputQuestion['option'+i].option);
          newFormData.append("key"+i, i);
          newFormData.append("value"+i, this.inputQuestion.answer == i ? 1 : 0);
          newFormData.append("picture"+i, this.inputQuestion['option'+i].picture);
          if(this.inputQuestion['option'+i].removeOld) {
            newFormData.append("removeOld"+i, this.inputQuestion['option'+i].removeOld);
          }
        }
      }
      if(this.inputQuestion.id) {
        newFormData.append("id", this.inputQuestion.id);
        this.updateQuestion(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.questionModal = false;
          this.showMessageBox("Berhasil update soal quiz", "Sukses", "success");
          this.quizList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.setNewQuestion(newFormData)
        .then(response => {
          this.showContentLoading = false;
          this.questionModal = false;
          this.showMessageBox("Berhasil menambah soal quiz", "Sukses", "success");
          this.quizList();
        })
        .catch(error => {
          this.showContentLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
      }
    },
    changePublish(item, type="") {
      var payload = {
        id: item.id
      }
      if(type == 'question') {
        payload.publish = item.publish ? 0 : 1
      } else {
        payload.publish = item.publish ? 1 : 0
      }
      if(type != 'question') {
        this.publishContent(payload)
        .then(response => {
          this.showMessageBox("Berhasil mengubah status publish", "Sukses", "success");
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.publishQuestion(payload)
        .then(response => {
          this.showMessageBox("Berhasil mengubah status publish", "Sukses", "success");
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
      }
    },
    changeFile() {
      var fileData = event.target.files[0];
      this.inputArticle.file = fileData;
      this.inputArticle.file_name = fileData.name;
      this.inputArticle.removeOld = 0;
    },
    removeFile() {
      this.inputArticle.file_name = "";
      this.inputArticle.file = null;
      this.inputArticle.removeOld = 1;
    },
    changePicture(numb) {
      var fileData = event.target.files[0];
      if(numb == 0) {
        this.inputQuestion.gallery = fileData;
        this.inputQuestion.gallery_url = URL.createObjectURL(fileData);
        this.inputQuestion.removeOld = 0;
      } else {
        this.inputQuestion['option'+numb].picture = fileData;
        this.inputQuestion['option'+numb].picture_url = URL.createObjectURL(fileData);
        this.inputQuestion['option'+numb].removeOld = 0;
      }
    },
    removePicture(numb) {
      if(numb == 0) {
        this.inputQuestion.gallery_url = "";
        this.inputQuestion.gallery = null;
        this.inputQuestion.removeOld = 1;
      } else {
        this.inputQuestion['option'+numb].picture_url = "";
        this.inputQuestion['option'+numb].picture = null;
        this.inputQuestion['option'+numb].removeOld = 1;
      }
    },
    buatContent() {
      this.showPopup = !this.showPopup;
    },
    handleItemClick(type, id=0) {
      if (type === "youtube") {
        this.ytModal = true;
        this.inputYoutube = {
          url:"",
          name: "",
          description: "",
          type: "youtube",
          publish: 0,
          id: null,
          duration: ""
        }
      }

      if (type === "video") {
        this.videoModal = true;
        this.inputVideo = {
          name: "",
          description: "",
          type: "video",
          player_id: "",
          publish: 0,
          id: null,
          duration: ""
        };
      }

      if (type === "article") {
        this.articleModal = true;
        this.inputArticle = {
          name: "",
          description: "",
          file: null,
          type: "pdf",
          publish: 0,
          file_name: "",
          id: null,
          removeOld: 0
        }
      }

      if (type === "quiz") {
        this.quizModal = true;
        this.inputQuiz = {
          name: "",
          duration: 0,
          id: null
        }
      }

      if (type === "question") {
        this.questionModal = true;
        this.inputQuestion = {
          quiz_id: id,
          id: null,
          answer: 1,
          question: "",
          gallery: "",
          gallery_url: "",
          removeOld: 0,
          option1: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option2: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option3: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option4: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option5: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          }
        }
      }
    },
    handleBlur() {
      setTimeout(() => {
        this.showPopup = false;
      }, 1000);
    },
    changeThumbnail() {
      var fileData = event.target.files[0];
      this.inputData.thumbnail = fileData;
      this.inputData.thumbnail_src = URL.createObjectURL(fileData);
      this.inputData.removeOld = 0;
    },
    removeImage() {
      this.inputData.thumbnail_src = "/img/category-icons/thumbnail-default.svg";
      this.inputData.thumbnail = null;
      this.inputData.removeOld = 1;
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  }
};
</script>

<style>
.quiz-question {
  margin-top: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #ececec;
}

.quiz-question:last-child {
  border-bottom: 0;
}

#ytModal .modal-dialog,
#articleModal .modal-dialog,
#questionModal .modal-dialog,
#videoModal .modal-dialog {
  max-width: 910px;
}

.cat-icon-container {
  width: 108px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f8ff;
}

.katex { 
  font-size: 10px !important;
  font-style: normal;
} 
</style>
